.project-list {
	--items-per-row: 4;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 1rem;
	justify-content: flex-start;

	> h2 {
		width: 100%;
		margin-bottom: 0;
	}

	/*
	&:nth-of-type(even) {

		> h2::after {
			left: -1rem;
			right: 0;
		}
	}
	*/

	/*
	> h2 {
		width: 100%;
		position: relative;
		color: rgba(var(--white));
		padding: 0 1rem;
		margin-bottom: 0;

		padding: 0 1rem 0.25rem;
		color: rgba(var(--black));

		&::after {
			content: '';
			background-color: rgba(var(--black));
			display: block;
			position: absolute;
			left: 0;
			right: -1rem;
			top: calc(100% - .25rem);
			bottom: 0;
			z-index: -1;
		}
	}
	*/
}

.project {
	@extend %content;
	flex: 0 0 calc((100% / var(--items-per-row)) - (1rem * ((var(--items-per-row) - 1)/ var(--items-per-row))));
	position: relative;
	padding: .25rem;

	&:hover,
	&:focus-within {

		> h3 {
			transform: translateX(0);
		}
	}

	> img {
		max-width: 100%;
		vertical-align: middle;
	}

	> a {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		text-indent: 100%;
		white-space: nowrap;

		&:focus {
			outline-offset: -.25rem;
		}
	}

	> h3 {
		background-color: rgba(var(--main));
		font-size: 1.5rem;
		position: absolute;
		padding: .25rem;
		left: 0;
		bottom: .5rem;
		z-index: 1;
		transform: translateX(-100%);
		transition: transform .125s linear;
	}
}

.project-images {
	display: flex;
	grid-gap: 1rem;

	> figure {

	}

	.gallery {
		flex: 0 0 100px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		list-style: none;
		grid-gap: 1rem;
		margin: 0;
	}
}

@media (max-width: 1024px) {
	.project-list {
		--items-per-row: 2;
	}
}

@media (max-width: 600px) {
	.project-list {
		--items-per-row: 1;
	}

	.project-images {
		flex-direction: column;

		.gallery {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			> li {
				flex: 0 0 calc(50% - .5rem);
			}
		}
	}
}
