// modal dialog
[is="x-modal-dialog-container"] {

	&:not(:empty)::before {
		content: '';
		background-color: rgba(var(--black), .75);
		display: block;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

[is="x-dialog"] {
	background: rgba(var(--white));
	border: 1px solid rgba(var(--black), .75);
	padding: 0;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	> img {
		max-width: 90vw;
		max-height: 90vh;
	}

	> button[value="close"] {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%) rotate(-1deg);
	}
}
