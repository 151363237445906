[is="x-abbr"] {

	&[aria-label] {
		cursor: help;
		position: relative;

		&:hover {

			&::after {
				content: attr(aria-label);
				background-color: rgba(var(--black));
				color: rgba(var(--white));
				font-size: 1rem;
				padding: .25rem;
				text-decoration: none;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				z-index: 1;
				white-space: nowrap;
			}
		}
	}
}
