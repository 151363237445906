[is="x-message"] {
	color: rgb(var(--white));
	padding: 0.5rem;

	form & {
		margin: 1rem 0 0 calc(100% / 3);
	}

	&[type="success"] {
		background-color: rgb(var(--green));
	}

	&[type="error"] {
		background-color: rgb(var(--red));
	}
}
