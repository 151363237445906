.error-message {
	color: red;
}

form {

	&:not([novalidate]) {

		.error-message {
			visibility: hidden;
		}
	}

	&[novalidate] {

		.error-message {
			visibility: hidden;
		}
	}

	&.submission-attempt {

		:invalid ~ .error-message {
			visibility: visible;
			transform: translate(0, 100%);
		}
	}

	> menu {
		display: flex;
		list-style: none;
		margin-left: calc(100% / 3);
		grid-gap: 1rem;
	}
}

input,
textarea {
	background-color: rgba(var(--white));
	padding: .25rem;
	border: 1px solid rgba(var(--black));
	font: 100%/1.2 'Londrina Solid';
	font-weight: lighter;
	transition: .03125s linear;

	&:-internal-autofill-selected {
		box-shadow: 0 0 0 1rem rgba(var(--white)) inset;
	}
}

textarea {
	min-height: calc(4.8rem * 1.2 + .5rem); // height + line-height + top/bottom padding
	resize: none;
}

button {
	background-color: rgba(var(--clickable));
	color: rgba(var(--white));
	cursor: pointer;
	padding: .5rem 1rem;
	border: none;
	position: relative;
	transform: rotate(-1deg);
	transition: .03125s linear;

	&[type="reset"] {
		background-color: rgba(var(--white));
		color: rgba(var(--black));
		transform: rotate(.75deg);
		border: 1px solid rgba(var(--black));

		&:hover,
		&:focus {
			transform: rotate(-1.25deg) scale(1.1);
		}
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: .5;
	}

	&:hover,
	&:focus {
		transform: rotate(2deg) scale(1.15);
	}

	&::after {
		content: '';
		background-color: rgba(var(--clickable));
		display: block;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: -1;
		transform: rotate(1deg);
		visibility: hidden;
	}
}

.form-field {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 2.5rem;
	position: relative;

	&:nth-child(2n + 2) > label::before {
		transform-origin: bottom left;
		transform: rotate(.625deg);
	}

	&:nth-child(3n + 3) > label::before {
		transform-origin: top left;
		transform: rotate(-.5deg);
	}

	&:nth-child(4n + 4) > label::before {
		transform-origin: top right;
		transform: rotate(.5deg);
	}

	> label {
		width: calc(100% / 3);
		position: relative;
		color: rgba(var(--white));
		padding: .25rem 1.25rem .25rem .25rem;
		text-align: right;

		&::before {
			content: '';
			background-color: rgba(var(--main));
			position: absolute;
			left: -1rem;
			top: 0;
			right: 1rem;
			bottom: 0;
			z-index: -1;
			transform: rotate(-.625deg);
			transform-origin: bottom right;
		}

		&.required::after {
			content: ' (required)';
			display: block;
			font-size: .75rem;
		}
	}

	> input,
	> textarea,
	> select {
		flex: 1;
		align-self: center;
	}

	> .error-message {
		position: absolute;
		left: -1rem;
		bottom: -.25rem;
		transform: translate(-100%, 100%);
		background: rgba(var(--red));
		padding: .125rem 1rem .125rem calc(100% / 3 + 1rem);
		overflow: hidden;
		transition: .125s linear;
		width: auto;
		white-space: nowrap;
	}
}

.error-message {
	color: rgba(var(--white));
}
